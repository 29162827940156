import React from 'react';
import { trackClickyGoal } from '../Utils/analytics';
import { useTheme } from '../ThemeContext';

const BuyButtons = () => {
  const { theme } = useTheme();
  
  return (
    <div className='flex-column flex'>
      <div className="d-flex flex-md-row flex-column gap-3">
        <a 
          href="https://app.uniswap.org/swap?chain=mainnet&inputCurrency=NATIVE&outputCurrency=0x2bc46eb4ae80ddd9c8a6e064c74327c8244d88e2" 
          target="_blank" 
          rel="noopener noreferrer" 
          className='primary-btn'
          onClick={() => trackClickyGoal(305)}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            background: '#F19938',
            border: 'none',
            color: '#000',
            fontWeight: 'bold',
            padding: '12px 24px',
            fontSize: '18px',
            minWidth: '220px',
            height: '56px',
            transition: 'all 0.3s ease',
            transform: 'translateY(0)',
          }}
          onMouseEnter={e => {
            e.currentTarget.style.transform = 'translateY(-4px)';
          }}
          onMouseLeave={e => {
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <span style={{ marginTop: '4px' }}>Buy on</span>
          <img src="assets/uniswap-logo-horizontal-black.svg" alt="Uniswap" width="110" height="28" />
        </a>
        
        <a 
          href="https://raydium.io/swap/?inputMint=sol&outputMint=9BpQUsVpGSBXX3fwi3U5Re12pufkiooxvVvoDCitFbJ5" 
          target="_blank" 
          rel="noopener noreferrer" 
          className='primary-btn'
          onClick={() => trackClickyGoal(324)}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            background: 'transparent',
            border: '1px solid #F19938',
            color: theme === 'light' ? '#000' : '#FFF',
            fontWeight: 'bold',
            padding: '12px 24px',
            fontSize: '17px',
            minWidth: '220px',
            height: '56px',
            transition: 'all 0.3s ease',
            transform: 'translateY(0)',
          }}
          onMouseEnter={e => {
            e.currentTarget.style.transform = 'translateY(-4px)';
          }}
          onMouseLeave={e => {
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <span style={{ marginTop: '4px' }}>Buy on</span>
          <img 
            src={theme === 'light' 
              ? "assets/raydium-logo-black.png" 
              : "assets/raydium-logo-white.png"} 
            alt="Raydium" 
            width="130" 
            height="34" 
          />
        </a>
      </div>
      <div className="d-flex flex-md-row flex-column gap-3">
        <a 
          href="https://www.coingecko.com/en/coins/stratovm" 
          target="_blank" 
          rel="noopener noreferrer"
          className='primary-btn'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            background: 'transparent',
            border: '0px',
            color: theme === 'light' ? '#000' : '#FFF',
            fontWeight: 'bold',
            padding: '24px 0px',
            marginTop: '20px',
            fontSize: '17px',
            minWidth: '220px',
            height: '56px',
            transition: 'all 0.3s ease',
            transform: 'translateY(0)',
          }}
          onMouseEnter={e => {
            e.currentTarget.style.transform = 'translateY(-4px)';
          }}
          onMouseLeave={e => {
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <span style={{ marginTop: '4px', color: '#F19938' }}>Listed on</span>
          <img 
            src={theme === 'light' 
              ? "assets/coingecko-logo-horizontal-black.svg" 
              : "assets/coingecko-logo-horizontal-white.svg"} 
            alt="CoinGecko" 
            width="130" 
            height="34" 
          />
        </a>
      </div>
    </div>
  );
};

export default BuyButtons; 