declare global {
  interface Window {
    clicky: {
      goal: (goalId: number) => void;
    };
  }
}

export const trackClickyGoal = (goalId: number) => {
    if (typeof window === 'undefined') {
      console.log('Clicky tracking failed: window is undefined');
      return;
    }
  
    if (!window.clicky) {
      console.log('Clicky tracking failed: window.clicky is not defined');
      return;
    }
  
    // if (!process.env.NEXT_PUBLIC_CLICKY_ID) {
    //   console.log('Clicky tracking failed: NEXT_PUBLIC_CLICKY_ID is not defined');
    //   return;
    // }
  
    try {
      window.clicky.goal(goalId);
      console.log(`Clicky goal tracked: ${goalId}`);
    } catch (error) {
      console.error('Failed to track Clicky goal:', error);
    }
  }; 